import { useFrame } from "@react-three/fiber";
import React, { useRef } from "react";
import Planet from "./Planet";
import OmPlanet from "./OmPlanet";
import HeartEyesPlanet from "./HeartEyesPlanet";
const Planets = (props) => {
  const { planetData } = props;
  const planets = useRef();

  useFrame(({ clock }) => {
    planets.current.rotation.y = clock.getElapsedTime() * 0.1;
  });
  return (
    <group ref={planets}>
      {planetData &&
        planetData.map((project, index) => {
          if (project.projectSlug.current === "no-5-heart-eyes") {
            return (
              <HeartEyesPlanet
                planetImage={project.planetImage}
                position={project.planetPosition}
                slug={`/projects/${project.projectSlug.current}`}
                key={index}
              />
            );
          } else {
            return (
              <Planet
                planetImage={project.planetImage}
                position={project.planetPosition}
                slug={`/projects/${project.projectSlug.current}`}
                key={index}
                om={false}
              />
            );
          }
        })}

      <OmPlanet
        planetImage={"/OmFries.png"}
        position={"-3,0,-3"}
        slug={"/OmFries"}
        om={false}
      />
    </group>
  );
};

export default Planets;
