import React from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { DoubleSide, TextureLoader } from "three";
import { useNavigate } from 'react-router-dom';
import { useRef } from "react";
import { degToRad } from "three/src/math/MathUtils";
const HeartEyesPlanet = (props) => {

  const { planetImage, position, slug, om } = props;
  const planet = useRef();
  const ring = useRef();
  const intPosition = position.split(',').map(Number);
  const map = useLoader(TextureLoader, planetImage);
  const ringMap = useLoader(TextureLoader, '/rings.png');
  const navigate = useNavigate();
  const handleClick = () => navigate(slug);

  useFrame(({clock})=>{
      planet.current.rotation.y = -clock.getElapsedTime() * 0.2;
      planet.current.position.y = Math.sin(clock.getElapsedTime()) * 1;
      ring.current.rotation.z = -clock.getElapsedTime() * 0.2;

  })

  return (
    <group ref={planet}>
      <mesh 
      ref={ring}
      rotation={[degToRad(110), 0, 0]}>
      <planeGeometry args={[4.2, 4.2, 100]}/>
      <meshBasicMaterial 
      color={'#FFFFFF'}
      map={ringMap} 
      side={DoubleSide}
      transparent={true}
      opacity={0.8}/>
      </mesh>
    <mesh 
   
    position={intPosition} 
    onPointerEnter={()=>{
        document.documentElement.style.cursor = 'pointer'
    }}
    onPointerLeave={()=>{
        document.documentElement.style.cursor = 'default'
    }}
    onClick={handleClick}>
      <sphereGeometry args={[1, 32, 64]}/>
      <meshBasicMaterial 
      color={'#FFFFFF'}
      map={map}
      />
    </mesh>
    </group>

  );
};

export default HeartEyesPlanet;
